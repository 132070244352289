<!--
 * @Author: Dyf
 * @LastEditors: Dyf
 * @Date: 2023-03-20 10:58:29
 * @LastEditTime: 2023-06-30 09:59
 * @Descripttion: 首页【已入驻】
-->
<style lang="scss" scoped>
.home {
    width: 100%;
    height: 100%;
    position: relative;
    box-sizing: border-box;
    padding-left: 120px;

    &::before {
        content: '';
        width: 100%;
        height: 308px;
        padding-top: 2.5vh;
        background: #4e329f;
        border-radius: 0px 0px 60px 0px;
        position: absolute;
        top: -2.5vh;
        left: 0;
    }

    &-inner.settled {
        width: calc(100% - 30px);
        height: 100%;
        margin: 0 0 0 30px;
        overflow: hidden;
        overflow-y: auto;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
        @include flexBox;

        .container {
            width: calc(100% - 30px);
            @include flexBox(space-between);
        }

        .no-data {
            background: #fff;
            border-radius: 10px;
            position: relative;

            &--empty {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);

                img {
                    width: 150px;
                    margin-bottom: 10px;
                }

                p {
                    color: #c1c1c1;
                    text-align: center;
                }
            }
        }

        .wrapper {
            &-top {
                width: 100%;
                height: 370px;
                margin-bottom: 28px;
                @include flexBox(space-between);

                .calendar {
                    &-box {
                        width: 514px;
                        height: 100%;
                        background: #fff;
                        border-radius: 10px;
                        overflow: hidden;
                    }

                    &-head {
                        height: 70px;
                        box-sizing: border-box;
                        padding: 0 24px 0 38px;
                        border-bottom: 1px solid rgba($color: #eaeaea, $alpha: .72);
                        position: relative;
                        @include flexBox(space-between);

                        h4 {
                            color: #252525;
                            @include flexBox;

                            .iconfont {
                                font-size: 28px;
                                color: #6340c8;
                                margin-right: 8px;
                            }
                        }

                        &::after {
                            content: '';
                            width: 38px;
                            height: 5px;
                            background-color: #6340c8;
                            border-radius: 2.5px;
                            position: absolute;
                            bottom: -1px;
                            left: 38px;
                        }
                    }

                    &--month {
                        line-height: 80px;
                        color: #292929;

                        .ari {
                            font-size: 30px;
                            font-weight: bolder;
                            margin-right: -4px;
                        }

                        .pf_bold {
                            font-size: 30px;
                            margin-right: 14px;
                        }

                        .iconfont {
                            font-size: 24px;
                            cursor: pointer;

                            .calendar-prev,
                            .calendar-next {
                                display: inline-block;
                                color: #3a3a3a;
                            }

                            .calendar-prev:hover,
                            .calendar-next:hover {

                                color: #6340c8;
                                transform: scale(1.2);
                            }
                        }
                    }

                    &-container {
                        box-sizing: border-box;
                        padding: 14px 32px 10px;

                        .week {
                            &-head {
                                height: 30px;
                                margin-bottom: 8px;
                                @include flexBox(space-between);

                                li {
                                    width: 40px;
                                    height: 30px;
                                    line-height: 30px;
                                    text-align: center;
                                    color: #0b0b0b;
                                }
                            }

                            &-days {
                                .days {
                                    height: 40px;
                                    flex-wrap: wrap;
                                    @include flexBox(space-between);

                                    li {
                                        width: 40px;
                                        height: 40px;
                                        line-height: 40px;
                                        text-align: center;
                                        color: #0b0b0b;
                                        border-radius: 8px;
                                        color: #222;
                                        font-size: 16px;
                                        cursor: pointer;
                                        flex-shrink: 0;
                                        margin-right: 26px;

                                        &:not(.selected):not(.disabled):hover {
                                            background: #f0edfa;
                                            color: #8971d5;
                                        }

                                        &.selected {
                                            background: #6340c8;
                                            color: #fff;
                                            font-weight: normal;
                                        }

                                        &.disabled {
                                            color: rgba($color: #949494, $alpha: .33);
                                            cursor: no-drop;
                                        }

                                        &:nth-child(7n) {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right {
                    width: calc(100% - 540px);
                    height: 100%;
                    position: relative;

                    .notice {
                        &-box {
                            height: 80px;
                            border-bottom: 1px solid rgba($color: #ede6ff, $alpha: 0.11);
                            @include flexBox;

                            .iconfont {
                                font-size: 30px;
                                color: #ede6ff;
                                margin-right: 14px;
                            }
                        }

                        &-wrapper {
                            width: calc(100% - 50px);
                            height: 40px;

                            p {
                                max-width: 600px;
                                line-height: 40px;
                                font-size: 16px;
                                color: #f7f7ff;
                            }
                        }
                    }

                    .course-arrange {
                        width: 80%;
                        max-width: 952px;
                        height: 258px;
                        margin-top: 32px;
                        position: relative;

                        &--wrapper {
                            width: calc(100% - 40px);
                            min-width: 160px;
                            height: 100%;

                            .swiper-slide {
                                width: 28%;
                                max-width: 185px;
                                min-width: 154px;
                                margin-right: 8px;
                                box-sizing: border-box;
                                padding-top: 8px;

                                &:last-child {
                                    margin-right: 0;
                                }

                                .course {
                                    &-item {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 10px;
                                        background: #fff;
                                        position: relative;

                                        &.prepare {
                                            background: #ffecea;

                                            h4 {
                                                color: #f66478;
                                            }

                                            .status {
                                                background-color: rgba($color: #fff, $alpha: 0.51);
                                                border-color: #efb8bf;
                                                color: #ee4f65;
                                            }
                                        }

                                        &.end {
                                            .status {
                                                background-color: #e1e1e1;
                                                border-color: #dedede;
                                                color: #b7b7b7;
                                            }
                                        }

                                        .time {
                                            height: 72px;
                                            line-height: 72px;
                                            width: 128px;
                                            margin: 0 auto;
                                            border-bottom: 1px solid rgba($color: #c5c5c5, $alpha: .14);
                                            text-align: center;
                                            color: #464646;
                                        }

                                        h4 {
                                            color: #2b2b2b;
                                            text-align: center;
                                            line-height: 38px;
                                            margin: 14px 0;
                                        }

                                        .status {
                                            width: 70px;
                                            height: 30px;
                                            background-color: rgba($color: #2ac293, $alpha: 0.06);
                                            border: 1px solid #9ee9d2;
                                            color: #2ac293;
                                            border-radius: 8px;
                                            text-align: center;
                                            line-height: 28px;
                                            margin: 0 auto;
                                        }

                                        .class {
                                            text-align: center;
                                            color: #464646;
                                            line-height: 24px;
                                            margin-top: 14px;
                                        }
                                    }
                                }
                            }
                        }

                        &--btns {
                            position: absolute;
                            right: -42px;
                            bottom: 0;
                            z-index: 3;
                            flex-direction: row;
                            @include flexBox(center);

                            .el-button {
                                width: 42px;
                                min-width: 42px;
                                height: 42px;
                                padding: 0;
                                font-size: 20px;
                                margin: 0;
                                margin-bottom: 2px;

                                &--custom_warning {
                                    &.swiper-button-disabled {
                                        background: #f9dfb0;
                                        border-color: #f9dfb0;
                                        cursor: default;
                                    }
                                }

                                &--custom_primary {
                                    &.swiper-button-disabled {
                                        background-color: #c0afee;
                                        border-color: #b8a7e8;
                                        cursor: default;
                                    }
                                }
                            }
                        }
                    }

                    .bitmap {
                        width: 33.6%;
                        max-width: 320px;
                        position: absolute;
                        bottom: 0;
                        right: -24.4%;
                    }
                }
            }

            &-left {
                width: 67.25%;
                height: 770px;

                .class {
                    width: 100%;
                    height: 154px;
                    margin-bottom: 12px;
                    box-sizing: border-box;
                    padding-right: 48px;
                    padding-top: 2px;
                    position: relative;

                    &--wrapper {
                        width: 100%;
                        min-width: 160px;
                        height: 146px;

                        .swiper-slide {
                            width: 30%;
                            max-width: 254px;
                            min-width: 200px;
                            margin-right: 6px;
                            box-sizing: border-box;
                            padding-top: 8px;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    &-item {
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        padding: 0 30px;
                        border-radius: 10px;
                        background: #f7f7ff;
                        border: 1px solid #f7f7ff;
                        position: relative;
                        @include flexBox;

                        &.current {
                            border-color: #4a29a7;
                        }

                        .info,
                        .entry {
                            flex-direction: column;
                            @include flexBox(center, flex-start)
                        }

                        .info {
                            margin-right: auto;
                            font-size: 16px;
                            line-height: 24px;
                            color: #5c5c5c;

                            .students {
                                font-size: 14px;
                                margin-top: 14px;

                                b {
                                    font-size: 30px;
                                    color: #2b2b2b;
                                }
                            }
                        }

                        .entry {
                            i {
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                border-radius: 8px;
                                margin-bottom: 10px;
                                font-size: 22px;
                                background: #d9d9f9;
                                color: #6340c8;
                                text-align: center;
                                line-height: 30px;
                                cursor: pointer;

                                &:last-child {
                                    margin-bottom: 0;
                                    background: #fee9c5;
                                    color: #feaf2f;
                                }

                                &.new-msg {
                                    position: relative;

                                    &::before {
                                        content: '';
                                        width: 12px;
                                        height: 12px;
                                        box-sizing: border-box;
                                        border: 2px solid #fff;
                                        border-radius: 50%;
                                        background: #f45555;
                                        position: absolute;
                                        top: -5px;
                                        right: -6px;
                                    }
                                }
                            }
                        }

                        &:hover {
                            border-color: #4a29a7;
                            background: #ede6ff;
                        }
                    }

                    &--btns {
                        height: 100%;
                        position: absolute;
                        right: 0;
                        top: 0;
                        z-index: 3;
                        flex-direction: column;
                        @include flexBox(center);

                        .el-button {
                            width: 42px;
                            min-width: 42px;
                            height: 42px;
                            padding: 0;
                            font-size: 20px;
                            margin: 0;
                            margin-bottom: 2px;

                            &--custom_primary {
                                &.swiper-button-disabled {
                                    background-color: #c0afee;
                                    border-color: #b8a7e8;
                                    cursor: default;
                                }
                            }
                        }
                    }

                    &.no-data {
                        background: #f7f7ff;

                        .el-button {
                            right: 42px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .no-data--empty {
                        @include flexBox;

                        p {
                            margin-left: 28px;
                        }
                    }
                }

                .trend-chart {
                    width: 100%;
                    height: calc(100% - 166px);

                    &--inner {
                        height: calc(100% - 72px);
                        box-sizing: border-box;
                        padding: 0 50px;
                        background: #fff;
                        border-radius: 0 0 10px 10px;
                        position: relative;

                        &.no-data {
                            border-radius: 0 0 10px 10px;
                        }

                        .echarts-legend {
                            position: absolute;
                            bottom: 38px;
                            left: 110px;
                            height: 24px;
                            @include flexBox;

                            li {
                                box-sizing: border-box;
                                padding-left: 26px;
                                position: relative;
                                color: #3f3f3f;
                                margin-right: 28px;

                                &::before {
                                    content: '';
                                    width: 20px;
                                    height: 10px;
                                    border-radius: 3px;
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }

                                &:nth-child(1)::before {
                                    background: #6340c8;
                                }

                                &:nth-child(2)::before {
                                    background: #ee4f65;
                                }

                                &:nth-child(3)::before {
                                    background: #feaf2f;
                                }
                            }
                        }
                    }

                    &--tabs {
                        width: calc(100% - 50px);
                        height: 82px;
                        margin-left: 50px;
                        border-bottom: 1px solid rgba($color: #626262, $alpha: 0.08);
                        box-sizing: border-box;
                        padding-top: 14px;
                        @include flexBox(space-between);

                        .charts-type {
                            width: 240px;
                            height: 68px;
                            position: relative;
                            margin-top: 6px;
                            margin-right: auto;
                            @include flexBox;

                            &:after {
                                content: '';
                                width: 38px;
                                height: 5px;
                                border-radius: 2.5px;
                                background: #58419c;
                                position: absolute;
                                left: 12px;
                                bottom: 0;
                                @include defaultAni;
                            }

                            &.exam::after {
                                transform: translateX(160px);
                            }

                            li {
                                cursor: pointer;
                                color: #373639;

                                &:first-child {
                                    padding-right: 52px;
                                }

                                &:last-child {
                                    padding-left: 52px;
                                    position: relative;

                                    &::after {
                                        content: '';
                                        height: 20px;
                                        border-left: 1px solid rgba($color: #7a7a7a, $alpha: 0.2);
                                        position: absolute;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                }

                                &.current,
                                &:not(.current):hover {
                                    color: #6c4ecb;
                                }
                            }
                        }

                        .subject {
                            max-width: 298px;

                            .swiper-slide {
                                width: auto;
                            }

                            ::v-deep .el-checkbox {
                                &__inner {
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 6px;

                                    &::after {
                                        font-size: 12px;
                                    }
                                }
                            }

                            &-btns {
                                .el-button {
                                    width: 32px;
                                    min-width: 32px;
                                    margin-left: 2px;

                                    &--custom_primary {
                                        &.swiper-button-disabled {
                                            background-color: #c0afee;
                                            border-color: #b8a7e8;
                                            cursor: default;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &--echarts {
                        width: 100%;
                        height: calc(100% - 82px);
                    }

                    .no-data--empty {
                        img {
                            width: 230px;
                        }

                        p {
                            margin-top: 40px;
                        }
                    }
                }
            }

            &-right {
                width: 32%;
                height: 770px;

                .schedule,
                .notice {
                    &--inner {
                        height: calc(100% - 72px);
                        background: #fff;
                        border-radius: 0 0 10px 10px;
                        box-sizing: border-box;
                        padding: 4px 20px;

                        &.no-data {
                            border-radius: 0 0 10px 10px;
                        }
                    }

                    &--list {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            height: 0;
                            width: 0;
                            color: transparent;
                        }
                    }

                    &-item {
                        width: 100%;
                        height: 82px;
                        box-sizing: border-box;
                        padding: 12px 15px;
                        border-bottom: 1px dashed rgba($color: #a2a2a2, $alpha: .17);
                        cursor: pointer;

                        &:hover {
                            border-radius: 10px;
                            background: #e9e9f4;
                            border-bottom: 1px solid #e9e9f4;

                            .title .line-text--1st {
                                color: #6340c8;
                            }
                        }

                        .time {
                            height: 24px;
                            margin-bottom: 6px;
                            color: #949495;
                            @include flexBox;

                            .iconfont {
                                font-size: 18px;
                                color: #666;
                                margin-right: 8px;
                            }
                        }

                        .title {
                            height: 22px;
                            @include flexBox;

                            .line-text--1st {
                                max-width: 100%;
                                margin-right: 10px;
                                color: #0b0b0b;
                                font-size: 16px;
                            }

                            .tag {
                                display: inline-block;
                                height: 22px;
                                font-size: 14px;
                                line-height: 22px;
                                padding: 0 6px;
                                border-radius: 0px 11px 11px 11px;
                                color: #fff;
                                background: #ee4f65;
                                flex-shrink: 0;
                            }
                        }
                    }
                }

                .schedule {
                    width: 100%;
                    height: 328px;
                    margin-bottom: 28px;
                }

                .notice {
                    width: 100%;
                    height: calc(100% - 356px);
                }
            }
        }
    }

    &-inner.notsettled {
        width: calc(100% - 30px);
        height: 100%;
        margin: 0 0 0 30px;
        overflow: hidden;
        overflow-y: auto;
        flex-wrap: wrap;
        position: relative;
        z-index: 2;
        @include flexBox;
        @include contentNoData;

        .container {
            width: calc(100% - 30px);
            @include flexBox(space-between);
        }

        .wrapper {
            &-top {
                width: 100%;
                height: 370px;
                margin-bottom: 28px;
                @include flexBox(space-between);

                .calendar {
                    &-box {
                        width: 514px;
                        height: 100%;
                        background: #fff;
                        border-radius: 10px;
                        overflow: hidden;
                    }

                    &-head {
                        height: 70px;
                        box-sizing: border-box;
                        padding: 0 24px 0 38px;
                        border-bottom: 1px solid rgba($color: #eaeaea, $alpha: .72);
                        position: relative;
                        @include flexBox(space-between);

                        h4 {
                            color: #252525;
                            @include flexBox;

                            .iconfont {
                                font-size: 28px;
                                color: #6340c8;
                                margin-right: 8px;
                            }
                        }

                        &::after {
                            content: '';
                            width: 38px;
                            height: 5px;
                            background-color: #6340c8;
                            border-radius: 2.5px;
                            position: absolute;
                            bottom: -1px;
                            left: 38px;
                        }
                    }

                    &--month {
                        line-height: 80px;
                        color: #292929;

                        .ari {
                            font-size: 30px;
                            font-weight: bolder;
                            margin-right: -4px;
                        }

                        .pf_bold {
                            font-size: 30px;
                            margin-right: 14px;
                        }

                        .iconfont {
                            font-size: 24px;
                            cursor: pointer;

                            .calendar-prev,
                            .calendar-next {
                                display: inline-block;
                                color: #3a3a3a;
                            }

                            .calendar-prev:hover,
                            .calendar-next:hover {

                                color: #6340c8;
                                transform: scale(1.2);
                            }
                        }
                    }

                    &-container {
                        box-sizing: border-box;
                        padding: 14px 32px 10px;

                        .week {
                            &-head {
                                height: 30px;
                                margin-bottom: 8px;
                                @include flexBox(space-between);

                                li {
                                    width: 40px;
                                    height: 30px;
                                    line-height: 30px;
                                    text-align: center;
                                    color: #0b0b0b;
                                }
                            }

                            &-days {
                                .days {
                                    height: 40px;
                                    flex-wrap: wrap;
                                    @include flexBox(space-between);

                                    li {
                                        width: 40px;
                                        height: 40px;
                                        line-height: 40px;
                                        text-align: center;
                                        color: #0b0b0b;
                                        border-radius: 8px;
                                        color: #222;
                                        font-size: 16px;
                                        cursor: pointer;
                                        flex-shrink: 0;
                                        margin-right: 26px;

                                        &.today {
                                            color: #8971d5;
                                            font-weight: bold;
                                        }

                                        &.selected,
                                        &:not(.selected):not(.disabled):hover {
                                            background: #6340c8;
                                            color: #fff;
                                            font-weight: normal;
                                        }

                                        &.disabled {
                                            color: rgba($color: #949494, $alpha: .33);
                                            cursor: no-drop;
                                        }

                                        &:nth-child(7n) {
                                            margin-right: 0;
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                .right {
                    width: calc(100% - 540px);
                    height: 100%;
                    position: relative;

                    .notice {
                        &-box {
                            height: 80px;
                            border-bottom: 1px solid rgba($color: #ede6ff, $alpha: 0.11);
                            @include flexBox;

                            .iconfont {
                                font-size: 30px;
                                color: #ede6ff;
                                margin-right: 14px;
                            }
                        }

                        &-wrapper {
                            width: calc(100% - 50px);
                            height: 40px;

                            p {
                                max-width: 600px;
                                line-height: 40px;
                                font-size: 16px;
                                color: #f7f7ff;
                            }
                        }
                    }

                    .course-arrange {
                        width: 80%;
                        max-width: 952px;
                        height: 258px;
                        margin-top: 32px;
                        position: relative;

                        &--wrapper {
                            width: calc(100% - 260px);
                            min-width: 160px;
                            height: 100%;

                            .swiper-slide {
                                width: 28%;
                                max-width: 185px;
                                min-width: 154px;
                                margin-right: 8px;
                                box-sizing: border-box;
                                padding-top: 8px;

                                &:last-child {
                                    margin-right: 0;
                                }

                                .course {
                                    &-item {
                                        width: 100%;
                                        height: 100%;
                                        border-radius: 10px;
                                        background: #fff;
                                        position: relative;

                                        &.prepare {
                                            background: #ffecea;
                                            cursor: pointer;

                                            h4 {
                                                color: #f66478;
                                            }

                                            .status {
                                                background-color: rgba($color: #fff, $alpha: 0.51);
                                                border-color: #efb8bf;
                                                color: #ee4f65;

                                                &:hover {
                                                    color: #fff;
                                                    background: #ee4f65;
                                                    border-color: #ee4f65;
                                                }
                                            }
                                        }

                                        &.end {
                                            .status {
                                                background-color: #e1e1e1;
                                                border-color: #dedede;
                                                color: #b7b7b7;
                                            }
                                        }

                                        .time {
                                            height: 72px;
                                            line-height: 72px;
                                            width: 128px;
                                            margin: 0 auto;
                                            border-bottom: 1px solid rgba($color: #c5c5c5, $alpha: .14);
                                            text-align: center;
                                            color: #464646;
                                        }

                                        h4 {
                                            color: #2b2b2b;
                                            text-align: center;
                                            line-height: 38px;
                                            margin: 14px 0;
                                        }

                                        .status {
                                            width: 70px;
                                            height: 30px;
                                            background-color: rgba($color: #2ac293, $alpha: 0.06);
                                            border: 1px solid #9ee9d2;
                                            color: #2ac293;
                                            border-radius: 8px;
                                            text-align: center;
                                            line-height: 28px;
                                            margin: 0 auto;
                                        }

                                        .class {
                                            text-align: center;
                                            color: #464646;
                                            line-height: 24px;
                                            margin-top: 14px;
                                        }
                                    }
                                }
                            }
                        }

                        &--btns {
                            position: absolute;
                            right: 214px;
                            bottom: 0;
                            z-index: 3;
                            flex-direction: row;
                            @include flexBox(center);

                            .el-button {
                                width: 42px;
                                min-width: 42px;
                                height: 42px;
                                padding: 0;
                                font-size: 20px;
                                margin: 0;
                                margin-bottom: 2px;

                                &--custom_warning {
                                    &.swiper-button-disabled {
                                        background: #f9dfb0;
                                        border-color: #f9dfb0;
                                        cursor: default;
                                    }
                                }

                                &--custom_primary {
                                    &.swiper-button-disabled {
                                        background-color: #c0afee;
                                        border-color: #b8a7e8;
                                        cursor: default;
                                    }
                                }
                            }
                        }

                        .btn-group {
                            width: 220px;
                            position: absolute;
                            right: 30px;
                            top: 6px;
                            z-index: 2;
                            @include flexBox(flex-end);

                            .el-button {
                                margin-left: 10px;
                            }
                        }

                        &.no-data {
                            .btn-group {
                                top: 30px;
                                right: 98px;
                            }
                        }
                    }

                    .bitmap {
                        width: 33.6%;
                        max-width: 320px;
                        position: absolute;
                        bottom: 0;
                        right: -24.4%;
                    }
                }
            }

            &-left {
                width: 67.25%;
                height: 770px;

                .class {
                    width: 100%;
                    height: 154px;
                    margin-bottom: 12px;
                    box-sizing: border-box;
                    padding-right: 200px;
                    padding-top: 2px;
                    position: relative;

                    &--wrapper {
                        width: 100%;
                        min-width: 160px;
                        height: 146px;

                        .swiper-slide {
                            width: 30%;
                            max-width: 254px;
                            min-width: 200px;
                            margin-right: 6px;
                            box-sizing: border-box;
                            padding-top: 8px;
                            position: relative;

                            &:last-child {
                                margin-right: 0;
                            }
                        }
                    }

                    &-item {
                        width: 100%;
                        height: 100%;
                        box-sizing: border-box;
                        padding: 0 30px;
                        border: 1px solid #f7f7ff;
                        border-radius: 10px;
                        background: #f7f7ff;
                        position: relative;
                        @include flexBox;

                        &.current {
                            border-color: #4a29a7;
                        }

                        .info,
                        .entry {
                            flex-direction: column;
                            @include flexBox(center, flex-start)
                        }

                        .info {
                            margin-right: auto;
                            font-size: 16px;
                            line-height: 24px;
                            color: #5c5c5c;

                            .students {
                                font-size: 14px;
                                margin-top: 14px;

                                b {
                                    font-size: 30px;
                                    color: #2b2b2b;
                                }
                            }
                        }

                        .entry {
                            i {
                                display: inline-block;
                                width: 30px;
                                height: 30px;
                                border-radius: 8px;
                                margin-bottom: 10px;
                                font-size: 22px;
                                background: #d9d9f9;
                                color: #6340c8;
                                text-align: center;
                                line-height: 30px;
                                cursor: pointer;

                                &:last-child {
                                    margin-bottom: 0;
                                    background: #fee9c5;
                                    color: #feaf2f;
                                }

                                &.new-msg {
                                    position: relative;

                                    &::before {
                                        content: '';
                                        width: 12px;
                                        height: 12px;
                                        box-sizing: border-box;
                                        border: 2px solid #fff;
                                        border-radius: 50%;
                                        background: #f45555;
                                        position: absolute;
                                        top: -5px;
                                        right: -6px;
                                    }
                                }
                            }
                        }

                        &:hover {
                            border-color: #4a29a7;
                            background: #ede6ff;
                        }
                    }

                    &--btns {
                        height: 100%;
                        position: absolute;
                        right: 154px;
                        top: 0;
                        z-index: 3;
                        flex-direction: column;
                        @include flexBox(center);

                        .el-button {
                            width: 42px;
                            min-width: 42px;
                            height: 42px;
                            padding: 0;
                            font-size: 20px;
                            margin: 0;
                            margin-bottom: 2px;

                            &--custom_primary {
                                &.swiper-button-disabled {
                                    background-color: #c0afee;
                                    border-color: #b8a7e8;
                                    cursor: default;
                                }
                            }
                        }
                    }

                    .add {
                        position: absolute;
                        right: 12px;
                        top: 2px;
                    }

                    &.no-data {
                        background: #f7f7ff;

                        .el-button {
                            right: 42px;
                            top: 50%;
                            transform: translateY(-50%);
                        }
                    }

                    .no-data--empty {
                        @include flexBox;

                        p {
                            margin-left: 28px;
                        }
                    }
                }

                .trend-chart {
                    width: 100%;
                    height: calc(100% - 166px);

                    &--inner {
                        height: calc(100% - 72px);
                        box-sizing: border-box;
                        padding: 0 50px;
                        background: #fff;
                        border-radius: 0 0 10px 10px;
                        position: relative;

                        &.no-data {
                            border-radius: 0 0 10px 10px;
                        }

                        .echarts-legend {
                            position: absolute;
                            bottom: 38px;
                            left: 110px;
                            height: 24px;
                            @include flexBox;

                            li {
                                box-sizing: border-box;
                                padding-left: 26px;
                                position: relative;
                                color: #3f3f3f;
                                margin-right: 28px;

                                &::before {
                                    content: '';
                                    width: 20px;
                                    height: 10px;
                                    border-radius: 3px;
                                    position: absolute;
                                    left: 0;
                                    top: 50%;
                                    transform: translateY(-50%);
                                }

                                &:nth-child(1)::before {
                                    background: #6340c8;
                                }

                                &:nth-child(2)::before {
                                    background: #ee4f65;
                                }

                                &:nth-child(3)::before {
                                    background: #feaf2f;
                                }
                            }
                        }
                    }

                    &--tabs {
                        width: calc(100% - 50px);
                        height: 82px;
                        margin-left: 50px;
                        border-bottom: 1px solid rgba($color: #626262, $alpha: 0.08);
                        box-sizing: border-box;
                        padding-top: 14px;
                        @include flexBox(space-between);

                        .charts-type {
                            width: 240px;
                            height: 68px;
                            position: relative;
                            margin-top: 6px;
                            margin-right: auto;
                            @include flexBox;

                            &:after {
                                content: '';
                                width: 38px;
                                height: 5px;
                                border-radius: 2.5px;
                                background: #58419c;
                                position: absolute;
                                left: 12px;
                                bottom: 0;
                                @include defaultAni;
                            }

                            &.exam::after {
                                transform: translateX(160px);
                            }

                            li {
                                cursor: pointer;
                                color: #373639;

                                &:first-child {
                                    padding-right: 52px;
                                }

                                &:last-child {
                                    padding-left: 52px;
                                    position: relative;

                                    &::after {
                                        content: '';
                                        height: 20px;
                                        border-left: 1px solid rgba($color: #7a7a7a, $alpha: 0.2);
                                        position: absolute;
                                        left: 0;
                                        top: 50%;
                                        transform: translateY(-50%);
                                    }
                                }

                                &.current,
                                &:not(.current):hover {
                                    color: #6c4ecb;
                                }
                            }
                        }

                        .subject {
                            max-width: 298px;

                            .swiper-slide {
                                width: auto;
                            }

                            ::v-deep .el-checkbox {
                                &__inner {
                                    width: 20px;
                                    height: 20px;
                                    margin-right: 6px;

                                    &::after {
                                        font-size: 12px;
                                    }
                                }
                            }

                            &-btns {
                                .el-button {
                                    width: 32px;
                                    min-width: 32px;
                                    margin-left: 2px;

                                    &--custom_primary {
                                        &.swiper-button-disabled {
                                            background-color: #c0afee;
                                            border-color: #b8a7e8;
                                            cursor: default;
                                        }
                                    }
                                }
                            }
                        }
                    }

                    &--echarts {
                        width: 100%;
                        height: calc(100% - 82px);
                    }

                    .no-data--empty {
                        img {
                            width: 230px;
                        }

                        p {
                            margin-top: 40px;
                        }
                    }
                }
            }

            &-right {
                width: 32%;
                height: 770px;

                .schedule,
                .notice {
                    &--inner {
                        height: calc(100% - 72px);
                        background: #fff;
                        border-radius: 0 0 10px 10px;
                        box-sizing: border-box;
                        padding: 4px 20px;

                        &.no-data {
                            border-radius: 0 0 10px 10px;
                        }
                    }

                    &--list {
                        width: 100%;
                        height: 100%;
                        overflow: hidden;
                        overflow-y: auto;

                        &::-webkit-scrollbar {
                            height: 0;
                            width: 0;
                            color: transparent;
                        }
                    }

                    &-item {
                        width: 100%;
                        height: 82px;
                        box-sizing: border-box;
                        padding: 12px 15px;
                        border-bottom: 1px dashed rgba($color: #a2a2a2, $alpha: .17);
                        cursor: pointer;

                        &:hover {
                            border-radius: 10px;
                            background: #e9e9f4;
                            border-bottom: 1px solid #e9e9f4;

                            .title .line-text--1st {
                                color: #6340c8;
                            }
                        }

                        .time {
                            height: 24px;
                            margin-bottom: 6px;
                            color: #949495;
                            @include flexBox;

                            .iconfont {
                                font-size: 18px;
                                color: #666;
                                margin-right: 8px;
                            }
                        }

                        .title {
                            height: 22px;
                            @include flexBox;

                            .line-text--1st {
                                max-width: 100%;
                                margin-right: 10px;
                                color: #0b0b0b;
                                font-size: 16px;
                            }

                            .tag {
                                display: inline-block;
                                height: 22px;
                                font-size: 14px;
                                line-height: 22px;
                                padding: 0 6px;
                                border-radius: 0px 11px 11px 11px;
                                color: #fff;
                                background: #ee4f65;
                                flex-shrink: 0;
                            }
                        }
                    }
                }

                .schedule {
                    width: 100%;
                    height: 328px;
                    margin-bottom: 28px;
                }

                .notice {
                    width: 100%;
                    height: calc(100% - 356px);
                }
            }
        }
    }
}

.dialog-container {
    width: calc(100% + 72px);
    margin-left: -36px;
    padding: 30px 0 10px;
    position: relative;

    img {
        width: 100%;
    }

    p {
        width: 100%;
        text-align: center;
        color: #afafaf;
        position: absolute;
        line-height: 2em;
        bottom: 24px;
        left: 0;
    }

    &--class {
        padding: 36px 0;
        box-sizing: border-box;
    }
}
</style>

<template>
    <section class="home">
        <!-- 已入驻 -->
        <div class="home-inner settled" v-if="userInfo.enter == 1">
            <div class="wrapper-top">
                <!-- 日历控件 -->
                <div class="calendar-box">
                    <div class="calendar-head">
                        <h4 class="bold">
                            <i class="iconfont">&#xe650;</i>
                            <span>课程表</span>
                        </h4>
                        <p class="calendar--month">
                            <span class="ari">{{ month[currentMonth - 1] }}，</span>
                            <span class="pf_bold">{{ currentYear }}</span>
                            <span class="iconfont">
                                <i class="calendar-prev" @click="changeMonth(1)">&#xe612;</i>
                                <i class="calendar-next" @click="changeMonth(2)">&#xe613;</i>
                            </span>
                        </p>
                    </div>
                    <div class="calendar-container">
                        <ul class="week-head">
                            <li v-for="(item, index) in week" :key="index">周{{ item }}</li>
                        </ul>
                        <div class="week-days pf_medium">
                            <ul class="days">
                                <li class="disabled" v-for="item in prevShowDays" :key="'p' + item">{{ item }}</li>
                                <li :class="{ selected: selectedDay == item.day, today: item.current }"
                                    v-for="item in currentShowDays" :key="'c' + item.num" @click="chooseDay(item.day)">
                                    {{ item.num }}
                                </li>
                                <li class="disabled" v-for="item in nextShowDays" :key="'n' + item">{{ item }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <!-- 滚动消息通知 -->
                    <div class="notice-box">
                        <i class="iconfont">&#xe651;</i>
                        <div class="notice-wrapper swiper">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide notice-item" v-for="item in noticeData" :key="item.pltea_mes_id">
                                    <p class="line-text--1st"
                                        @click="$router.push({ name: 'HOME_NOTIFYDETAIL', params: { id: scope.row.pltea_mes_id } })">
                                        {{ item.pltea_mes_title }}
                                    </p>
                                </div>
                                <div class="swiper-slide notice-item" v-if="$isEmpty(noticeData)">
                                    <p>暂无消息通知</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 课程安排 -->
                    <div class="course-arrange" :class="{ 'no-data': $isEmpty(courseArrangeData) }">
                        <img class="bitmap" src="@assets/images/bitmap-home.png" alt="">
                        <template v-if="!$isEmpty(courseArrangeData)">
                            <div class="course-arrange--wrapper swiper">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="item in courseArrangeData" :key="item.sccla_sch_id">
                                        <div class="course-item"
                                            :class="{ end: item.is_lessons == 3, prepare: item.is_lessons == 2 }">
                                            <p class="time pf_medium">
                                                {{ item.sccla_sch_tim_starttime }} ~ {{ item.sccla_sch_tim_endtime }}
                                            </p>
                                            <h4 class="bold">{{ item.sysub_name }}</h4>
                                            <p class="status" @click="toPrepare(item.is_lessons)">
                                                {{ item.is_lessons == 3 && '已结束' || item.is_lessons == 1 && '已备课' || '去备课'
                                                }}
                                            </p>
                                            <p class="class">{{ item.sccla_name }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="course-arrange--btns" v-if="courseArrangeData.length > 4">
                                <el-button class="iconfont course-arrange--prev" type="custom_warning" size="mini">
                                    &#xe612;
                                </el-button>
                                <el-button class="iconfont course-arrange--next" type="custom_primary" size="mini">
                                    &#xe613;
                                </el-button>
                            </div>
                        </template>
                        <div class="no-data--empty" v-if="$isEmpty(courseArrangeData)">
                            <img src="@assets/images/no-data3.png" alt="">
                            <p>暂无课程信息哦~</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="wrapper-left">
                    <!-- 班级 -->
                    <div class="class" :class="{ 'no-data': $isEmpty(classData) }">
                        <template v-if="!$isEmpty(classData)">
                            <div class="class--wrapper swiper">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="item in classData" :key="item.sccla_id">
                                        <div class="class-item" :class="{ current: teacherSelectClassIds == item.sccla_id }"
                                            @click="selectClass(item.sccla_id)">
                                            <p class="info">
                                                <span>{{ item.sccla_grade_name }}{{ item.sccla_name }}</span>
                                                <span class="students">
                                                    <b class="pf_bold">{{ item.student_num }}</b>人
                                                </span>
                                            </p>
                                            <p class="entry iconfont">
                                                <i :class="{ 'new-msg': item.student_msg_num > 0 }"
                                                    @click="$router.push({ name: 'HOME_TEACHINGCLASS', params: { grade: item.sccla_grade, class: item.sccla_id } })">
                                                    &#xe64d;
                                                </i>
                                                <i
                                                    @click="$router.push({ name: 'HOME_STUDYANALYSIS', params: { grade: item.sccla_grade, class: item.sccla_id } })">
                                                    &#xe64c;
                                                </i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="class--btns" v-if="classData.length > 4">
                                <el-button class="iconfont class--prev" type="custom_primary" size="mini">
                                    &#xe612;
                                </el-button>
                                <el-button class="iconfont class--next" type="custom_primary" size="mini">
                                    &#xe613;
                                </el-button>
                            </div>
                        </template>
                        <div class="no-data--empty" v-if="$isEmpty(classData)">
                            <img src="@assets/images/no-data3.png" alt="">
                            <p>暂无班级信息哦~</p>
                        </div>
                    </div>
                    <!-- 平均成绩趋势 -->
                    <div class="trend-chart">
                        <h4 class="box-head">
                            <span class="bold">平均成绩趋势</span>
                        </h4>
                        <div class="trend-chart--inner" :class="{ 'no-data': $isEmpty(subjectChartData) }">
                            <template v-if="!$isEmpty(subjectChartData)">
                                <div class="trend-chart--tabs">
                                    <ul class="charts-type" :class="{ exam: homeworkExamination == 2 }">
                                        <li @click="getSwitchGrade(1)" :class="{ 'current': homeworkExamination == 1 }">作业成绩
                                        </li>
                                        <li @click="getSwitchGrade(2)" :class="{ 'current': homeworkExamination == 2 }">考试成绩
                                        </li>
                                    </ul>
                                    <el-checkbox-group v-model="chartSubject" :max="3" :min="1">
                                        <div class="subject swiper">
                                            <div class="swiper-wrapper">
                                                <div class="swiper-slide" v-for="(item, index) in teachSubject"
                                                    :key="index">
                                                    <el-checkbox :label="item.sysub_id" @change="getTeaChickSub">
                                                        {{ item.sysub_name }}
                                                    </el-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </el-checkbox-group>
                                    <div class="subject-btns" v-if="teachSubject.length > 3">
                                        <el-button class="iconfont subject--prev" type="custom_primary" size="mini">
                                            &#xe612;
                                        </el-button>
                                        <el-button class="iconfont subject--next" type="custom_primary" size="mini">
                                            &#xe613;
                                        </el-button>
                                    </div>
                                </div>
                                <div class="trend-chart--echarts" ref="trend">
                                </div>
                                <ul class="echarts-legend">
                                    <template v-for="item in teachSubject">
                                        <li v-if="chartSubject.indexOf(item.sysub_id) != -1" :key="item.sysub_id">
                                            {{ item.sysub_name }}
                                        </li>
                                    </template>
                                </ul>
                            </template>
                            <div class="no-data--empty" v-if="$isEmpty(subjectChartData)">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无成绩信息哦~</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wrapper-right">
                    <!-- 待办事项 -->
                    <div class="schedule">
                        <h4 class="box-head">
                            <span class="bold">待办事项</span>
                            <router-link class="view-all" :to="{ name: 'HOME_STUDENTTRANSFER' }"
                                v-if="!$isEmpty(scheduleData) && scheduleData.length > 9">
                                <span>查看全部</span>
                                <span class="iconfont">&#xe601;</span>
                            </router-link>
                        </h4>
                        <div class="schedule--inner" :class="{ 'no-data': $isEmpty(scheduleData) }">
                            <ul class="schedule--list" v-if="!$isEmpty(scheduleData)">
                                <li class="schedule-item" v-for="item in scheduleData" :key="item.testu_exa_id"
                                    @click="$router.push({ name: 'HOME_STUDENTTRANSFER' })">
                                    <p class="time">
                                        <i class="iconfont">&#xe61a;</i>
                                        <span>
                                            {{ item.create_time | formatTime('MM月DD日') }}
                                            {{ dayOfWeek(item.create_time) }} 丨
                                            {{ item.create_time | formatTime('HH:mm') }}
                                        </span>
                                    </p>
                                    <p class="title">
                                        <span class="line-text--1st">你有一条新的待审核事项需要审核，请尽快审核</span>
                                        <i class="tag">
                                            {{ item.testu_exa_type == 10 && '新注册' || item.testu_exa_type == 20 && '转班' ||
                                                '转校' }}
                                        </i>
                                    </p>
                                </li>
                            </ul>
                            <div class="no-data--empty" v-if="$isEmpty(scheduleData)">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无信息哦~</p>
                            </div>
                        </div>
                    </div>
                    <!-- 通知公告 -->
                    <div class="notice">
                        <h4 class="box-head">
                            <span class="bold">通知公告</span>
                            <router-link class="view-all" :to="{ name: 'HOME_NOTIFY' }"
                                v-if="!$isEmpty(schoolNoticeData) && schoolNoticeData.length > 9">
                                <span>查看全部</span>
                                <span class="iconfont">&#xe601;</span>
                            </router-link>
                        </h4>
                        <div class="notice--inner" :class="{ 'no-data': $isEmpty(schoolNoticeData) }">
                            <ul class="notice--list" v-if="!$isEmpty(schoolNoticeData)">
                                <li class="notice-item" v-for="item in schoolNoticeData" :key="item.pltea_mes_id"
                                    @click="$router.push({ name: 'HOME_NOTIFYDETAIL', params: { id: item.pltea_mes_id } })">
                                    <p class="time">
                                        <i class="iconfont">&#xe61a;</i>
                                        <span>
                                            {{ item.create_time * 1000 | formatTime('MM月DD日') }}
                                            {{ dayOfWeek(item.create_time * 1000) }} 丨
                                            {{ item.create_time * 1000 | formatTime('HH:mm') }}
                                        </span>
                                    </p>
                                    <p class="title">
                                        <span class="line-text--1st">{{ item.pltea_mes_title }}</span>
                                    </p>
                                </li>
                            </ul>
                            <div class="no-data--empty" v-if="$isEmpty(schoolNoticeData)">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无信息哦~</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 未入驻 -->
        <div class="home-inner notsettled" v-if="userInfo.enter == 2">
            <div class="wrapper-top">
                <!-- 日历控件 -->
                <div class="calendar-box">
                    <div class="calendar-head">
                        <h4 class="bold">
                            <i class="iconfont">&#xe650;</i>
                            <span>课程表</span>
                        </h4>
                        <p class="calendar--month">
                            <span class="ari">{{ month[currentMonth - 1] }}，</span>
                            <span class="pf_bold">{{ currentYear }}</span>
                            <span class="iconfont">
                                <i class="calendar-prev" @click="changeMonth(1)">&#xe612;</i>
                                <i class="calendar-next" @click="changeMonth(2)">&#xe613;</i>
                            </span>
                        </p>
                    </div>
                    <div class="calendar-container">
                        <ul class="week-head">
                            <li v-for="(item, index) in week" :key="index">周{{ item }}</li>
                        </ul>
                        <div class="week-days pf_medium">
                            <ul class="days">
                                <li class="disabled" v-for="item in prevShowDays" :key="'p' + item">{{ item }}</li>
                                <li :class="{ selected: selectedDay == item.day, today: item.current }"
                                    v-for="item in currentShowDays" :key="'c' + item.num" @click="chooseDay(item.day)">
                                    {{ item.num }}
                                </li>
                                <li class="disabled" v-for="item in nextShowDays" :key="'n' + item">{{ item }}</li>
                            </ul>
                        </div>
                    </div>
                </div>
                <div class="right">
                    <!-- 滚动消息通知 -->
                    <div class="notice-box">
                        <i class="iconfont">&#xe651;</i>
                        <div class="notice-wrapper swiper">
                            <div class="swiper-wrapper">
                                <div class="swiper-slide notice-item" v-for="item in noticeData" :key="item.pltea_mes_id">
                                    <p class="line-text--1st"
                                        @click="$router.push({ name: 'HOME_NOTIFYDETAIL', params: { id: scope.row.pltea_mes_id } })">
                                        {{ item.pltea_mes_title }}
                                    </p>
                                </div>
                                <div class="swiper-slide notice-item" v-if="$isEmpty(noticeData)">
                                    <p>暂无消息通知</p>
                                </div>
                            </div>
                        </div>
                    </div>
                    <!-- 教师日课程表 -->
                    <div class="course-arrange" :class="{ 'no-data': $isEmpty(courseArrangeData) }">
                        <div class="btn-group">
                            <el-button type="custom_primary" size="medium" @click="addCourse">
                                添加课程+
                            </el-button>
                            <el-button type="custom_primary" size="medium" @click="$router.push({ name: 'HOME_TEXTBOOK' })">
                                教材管理+
                            </el-button>
                        </div>
                        <img class="bitmap" src="@assets/images/bitmap-home.png" alt="">
                        <template v-if="!$isEmpty(courseArrangeData)">
                            <div class="course-arrange--wrapper swiper">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="item in courseArrangeData" :key="item.sccla_sch_id">
                                        <div class="course-item"
                                            :class="{ end: item.is_lessons == 3, prepare: item.is_lessons == 2 }">
                                            <p class="time pf_medium">
                                                {{ item.sccla_sch_tim_starttime }} ~ {{ item.sccla_sch_tim_endtime }}
                                            </p>
                                            <h4 class="bold">{{ item.sysub_name }}</h4>
                                            <p class="status" @click="toPrepare(item.is_lessons)">
                                                {{ item.is_lessons == 3 && '已结束' || item.is_lessons == 1 && '已备课' || '去备课'
                                                }}
                                            </p>
                                            <p class="class">{{ `${item.sccla_sch_grade}${item.sccla_name}` }}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="course-arrange--btns" v-if="courseArrangeData.length > 3">
                                <el-button class="iconfont course-arrange--prev" type="custom_warning" size="mini">
                                    &#xe612;
                                </el-button>
                                <el-button class="iconfont course-arrange--next" type="custom_primary" size="mini">
                                    &#xe613;
                                </el-button>
                            </div>
                        </template>
                        <div class="no-data--empty" v-if="$isEmpty(courseArrangeData)">
                            <img src="@assets/images/no-data3.png" alt="">
                            <p>暂无课程信息哦~</p>
                        </div>
                    </div>
                </div>
            </div>
            <div class="container">
                <div class="wrapper-left">
                    <!-- 班级信息 -->
                    <div class="class" :class="{ 'no-data': $isEmpty(classData) }">
                        <el-button class="add" type="custom_primary" size="medium"
                            @click="$router.push({ name: 'HOME_CLASSESGRADES' })">
                            添加班级+
                        </el-button>
                        <template v-if="!$isEmpty(classData)">
                            <div class="class--wrapper swiper">
                                <div class="swiper-wrapper">
                                    <div class="swiper-slide" v-for="(item, index) in classData" :key="index">
                                        <div class="class-item" :class="{ current: teacherSelectClassIds == item.sccla_id }"
                                            @click="selectClass(item.sccla_id)">
                                            <p class="info">
                                                <span>{{ item.sccla_grade_name }}{{ item.sccla_name }}</span>
                                                <span class="students">
                                                    <b class="pf_bold">{{ item.student_num }}</b>人
                                                </span>
                                            </p>
                                            <p class="entry iconfont">
                                                <i :class="{ 'new-msg': item.student_msg_num > 0 }"
                                                    @click="$router.push({ name: 'HOME_TEACHINGCLASS', params: { grade: item.sccla_grade, class: item.sccla_id } })">
                                                    &#xe64d;
                                                </i>
                                                <i
                                                    @click="$router.push({ name: 'HOME_STUDYANALYSIS', params: { grade: item.sccla_grade, class: item.sccla_id } })">
                                                    &#xe64c;
                                                </i>
                                            </p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="class--btns" v-if="classData.length > 3">
                                <el-button class="iconfont class--prev" type="custom_primary" size="mini">
                                    &#xe612;
                                </el-button>
                                <el-button class="iconfont class--next" type="custom_primary" size="mini">
                                    &#xe613;
                                </el-button>
                            </div>
                        </template>
                        <div class="no-data--empty" v-if="$isEmpty(classData)">
                            <img src="@assets/images/no-data3.png" alt="">
                            <p>暂无班级信息哦~</p>
                        </div>
                    </div>
                    <!-- 成绩趋势图表 -->
                    <div class="trend-chart">
                        <h4 class="box-head">
                            <span class="bold">平均成绩趋势</span>
                        </h4>
                        <div class="trend-chart--inner" :class="{ 'no-data': $isEmpty(subjectChartData) }">
                            <template v-if="!$isEmpty(subjectChartData)">
                                <div class="trend-chart--tabs">
                                    <ul class="charts-type" :class="{ exam: homeworkExamination == 2 }">
                                        <li @click="getSwitchGrade(1)" :class="{ 'current': homeworkExamination == 1 }">作业成绩
                                        </li>
                                        <li @click="getSwitchGrade(2)" :class="{ 'current': homeworkExamination == 2 }">考试成绩
                                        </li>
                                    </ul>
                                    <el-checkbox-group v-model="chartSubject" :max="3" :min="1">
                                        <div class="subject swiper">
                                            <div class="swiper-wrapper">
                                                <div class="swiper-slide" v-for="(item, index) in teachSubject"
                                                    :key="index">
                                                    <el-checkbox :label="item.sysub_id" @change="getTeaChickSub">
                                                        {{ item.sysub_name }}
                                                    </el-checkbox>
                                                </div>
                                            </div>
                                        </div>
                                    </el-checkbox-group>
                                    <div class="subject-btns" v-if="teachSubject.length > 3">
                                        <el-button class="iconfont subject--prev" type="custom_primary" size="mini">
                                            &#xe612;
                                        </el-button>
                                        <el-button class="iconfont subject--next" type="custom_primary" size="mini">
                                            &#xe613;
                                        </el-button>
                                    </div>
                                </div>
                                <div class="trend-chart--echarts" ref="trend">
                                </div>
                                <ul class="echarts-legend">
                                    <template v-for="item in teachSubject">
                                        <li v-if="chartSubject.indexOf(item.sysub_id) != -1" :key="item.sysub_id">
                                            {{ item.sysub_name }}
                                        </li>
                                    </template>
                                </ul>
                            </template>
                            <div class="no-data--empty" v-if="$isEmpty(subjectChartData)">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无成绩信息哦~</p>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="wrapper-right">
                    <!-- 待办事项 -->
                    <div class="schedule">
                        <h4 class="box-head">
                            <span class="bold">待办事项</span>
                            <router-link class="view-all" :to="{ name: 'HOME_STUDENTTRANSFER' }"
                                v-if="!$isEmpty(scheduleData) && scheduleData.length > 9">
                                <span>查看全部</span>
                                <span class="iconfont">&#xe601;</span>
                            </router-link>
                        </h4>
                        <div class="schedule--inner" :class="{ 'no-data': $isEmpty(scheduleData) }">
                            <ul class="schedule--list" v-if="!$isEmpty(scheduleData)">
                                <li class="schedule-item" v-for="item in scheduleData" :key="item.testu_exa_id"
                                    @click="$router.push({ name: 'HOME_STUDENTTRANSFER' })">
                                    <p class="time">
                                        <i class="iconfont">&#xe61a;</i>
                                        <span>
                                            {{ item.create_time | formatTime('MM月DD日') }}
                                            {{ dayOfWeek(item.create_time) }} 丨
                                            {{ item.create_time | formatTime('HH:mm') }}
                                        </span>
                                    </p>
                                    <p class="title">
                                        <span class="line-text--1st">你有一条新的待审核事项需要审核，请尽快审核</span>
                                        <i class="tag">
                                            {{ item.testu_exa_type == 10 && '新注册' || item.testu_exa_type == 20 && '转班' ||
                                                '转校' }}
                                        </i>
                                    </p>
                                </li>
                            </ul>
                            <div class="no-data--empty" v-if="$isEmpty(scheduleData)">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无信息哦~</p>
                            </div>
                        </div>
                    </div>
                    <!-- 通知公告 -->
                    <div class="notice">
                        <h4 class="box-head">
                            <span class="bold">通知公告</span>
                            <router-link class="view-all" :to="{ name: 'HOME_NOTIFY' }"
                                v-if="!$isEmpty(schoolNoticeData) && schoolNoticeData.length > 9">
                                <span>查看全部</span>
                                <span class="iconfont">&#xe601;</span>
                            </router-link>
                        </h4>
                        <div class="notice--inner" :class="{ 'no-data': $isEmpty(schoolNoticeData) }">
                            <ul class="notice--list" v-if="!$isEmpty(schoolNoticeData)">
                                <li class="notice-item" v-for="item in schoolNoticeData" :key="item.pltea_mes_id"
                                    @click="$router.push({ name: 'HOME_NOTIFYDETAIL', params: { id: item.pltea_mes_id } })">
                                    <p class="time">
                                        <i class="iconfont">&#xe61a;</i>
                                        <span>
                                            {{ item.create_time * 1000 | formatTime('MM月DD日') }}
                                            {{ dayOfWeek(item.create_time * 1000) }} 丨
                                            {{ item.create_time * 1000 | formatTime('HH:mm') }}
                                        </span>
                                    </p>
                                    <p class="title">
                                        <span class="line-text--1st">{{ item.pltea_mes_title }}</span>
                                    </p>
                                </li>
                            </ul>
                            <div class="no-data--empty" v-if="$isEmpty(schoolNoticeData)">
                                <img src="@assets/images/no-data3.png" alt="">
                                <p>暂无信息哦~</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <!-- 提示弹窗 -->
        <el-dialog title="提示" width="558px" :visible.sync="timeDialog" @close="hideDialog">
            <div class="dialog-container">
                <img src="@assets/images/no-data.png" alt="" />
                <p v-if="!isExist">暂未设置课程表时间，请前往设置~</p>
                <p v-if="$isEmpty(classData) && isExist">暂未添加管理班级，请前往添加~</p>
            </div>
            <div slot="footer" class="foot-center">
                <el-button type="custom_primary" size="medium" @click="setTimetable">
                    {{ !isExist && '去设置' || $isEmpty(classData) && '去添加' }}
                </el-button>
            </div>
        </el-dialog>
        <!-- 选择课程配置班级 -->
        <el-dialog title="选择班级" width="420px" :visible.sync="classDialog" :show-close="false" @close="hideDialog">
            <div class="dialog-container--class">
                <el-form :model="timeAboutClass" ref="timeAboutClass">
                    <el-form-item prop="class" :rules="{ required: true, message: '请选择课程表关联班级', trigger: 'change' }">
                        <el-select v-model="timeAboutClass.class" placeholder="请选择班级" filterable clearable>
                            <el-option v-for="item in classData" :key="item.value"
                                :label="`${item.sccla_grade_name}${item.sccla_name}`"
                                :value="`${item.sccla_grade},${item.sccla_id}`" />
                        </el-select>
                    </el-form-item>
                </el-form>
            </div>
            <div slot="footer">
                <el-button type="custom_info" size="small" @click="hideDialog">取消</el-button>
                <el-button type="custom_primary" size="small" @click="tapLessonSet">确认</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
import * as home from "@api/home"
import { $getTeachSubject, $getSubjectCountData } from "@api/teaching";
import { mapState , mapActions } from "vuex"
import { formatTime, formatTimeStamp } from "@utils"
import Swiper, { Autoplay, FreeMode, Navigation } from "swiper";
import "swiper/swiper.min.css";


Swiper.use([Autoplay, FreeMode, Navigation]);

export default {
    name: 'home',
    computed: {
        ...mapState("common", ['classList']),
        ...mapState("user", ['userInfo']),
        /* 展示当月所有日期 */
        currentShowDays() {
            // 有3个参数，第一个是年，第二个是月，然后输入0代表当前月份   这样就可以获取天数了
            let currentDays = new Date(this.currentYear, this.currentMonth, 0).getDate();
            let daysArr = []
            for (let d = 1; d <= currentDays; d++) {
                let obj = {
                    num: d,
                    day: formatTime(`${this.currentYear}-${this.currentMonth}-${d}`, 'YYYY-MM-DD')
                }
                daysArr.push(obj)
            }
            return daysArr
        },
        /* 展示上月部分日期 */
        prevShowDays() {
            let prevTotal = new Date(this.currentYear, this.currentMonth - 1, 0).getDate();
            let prevSurplus = new Date(this.currentYear, this.currentMonth - 1, 0).getDay();
            let prevDays = [];
            for (let index = 0; index < prevSurplus; index++) {
                prevDays.push(prevTotal - index)
            }

            return this.$_.sortBy(prevDays);
        },
        /* 展示下月部分日期 */
        nextShowDays() {
            let prevSurplus = new Date(this.currentYear, this.currentMonth - 1, 0).getDay();
            let currentDays = new Date(this.currentYear, this.currentMonth, 0).getDate()
            return 42 - (prevSurplus + currentDays)
        },
        dayOfWeek() {
            return function (val) {
                let time = new Date(val).getTime();
                return `星期${formatTime(time, 'd')}`;
            }
        }
    },
    watch: {
        classList: {
            handler(val) {
                if (!this.$isEmpty(val)) {
                    this.classData = [...val];
                    if (!this.classSwiper) {
                        this.$nextTick(() => {
                            this.initSwiper(3);
                        })
                    }
                }
            },
            immediate: true,
        }
    },
    data() {
        return {
            noticeData: [], // 消息通知数据
            noticeSwiper: null, // 消息通知轮播实例
            courseArrangeData: [], // 日课程表数据
            courseArrangeSwiper: null, // 日课程表轮播实例
            classData: [], // 班级列表
            classSwiper: null, // 班级列表轮播实例
            scheduleData: [], // 待办事项列表
            schoolNoticeData: [], // 学校通知数据（通知公告）
            isExist: true, // 课程时间是否设置
            timeDialog: false, // 显示弹窗
            classDialog: false, // 班级选择弹窗
            timeAboutClass: {},
            trendSubjectSwiper: null, // 成绩趋势图科目轮播实例
            teachSubject: [], // 教师科目表
            chartSubject: [], // 教师选中科目数据源
            teacherSelectClassIds: 0, // 当前教师选中的班级
            subjectChartData: {}, // 统计图数据源
            seriesData: [], // 图表y轴数据组
            xAxisData: [], // 图表x轴数据
            homeworkExamination: 1, // 默认查询考试成绩还是作业成绩，1=作业成绩，2=考试成绩

            /* 自定义日历 */
            week: ['一', '二', '三', '四', '五', '六', '日'], // 周
            month: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'], // 月
            currentMonth: new Date().getMonth() + 1, // 当前月
            currentYear: new Date().getFullYear(), // 当前年
            selectedDay: formatTime(new Date(), 'YYYY-MM-DD'), // 当前选中日期
        }
    },
    async created() {
        this.getCourseArrangeData();
        this.getNoticeData();
        this.getScheduleList();
        this.getSchoolNotice();
        setTimeout(() => {
            //防止获取教师的id加载不及时加的等待
            this.getTeachSubject(); // 获取教师当前科目
        }, 500);
        //升学
        this.isupgradation()
    },
    methods: {
        ...mapActions("common", ['getClass']),
        /** 切换成绩 */
        getSwitchGrade(val) {
            this.homeworkExamination = val;
            this.getTeaChickSub();
        },
        /** 获取当前统计图数据 */
        async getTeaChickSub() {
            let chartCountParams = {
                sub_id: this.chartSubject.sort(function(x,y){return x-y}),
                sccla_id: this.teacherSelectClassIds,
                type: this.homeworkExamination
            };
            // 通过当前科目ID 查询科目统计数据
            let { data } = await $getSubjectCountData(chartCountParams);
            this.subjectChartData = data;
            this.xAxisData = data.x_data || [];
            let y_data = data.y_data || [];
            let seriesData = [];
            let colors = ['#987ee8', '#ee4f65', '#feaf2f'],
                shadowColors = ['rgba(99, 64, 200, 0.53)', 'rgba(238, 79, 101, 0.53)', 'rgba(254, 175, 47, 0.53)'],
                areaColors = ['152, 126, 232', '238, 79, 101', '254, 175, 47'];
            if (!this.$isEmpty(y_data)) {
                y_data.map((item, index) => {
                    let yData = {
                        type: 'line',
                        data: item,
                        symbol: 'circle',
                        showSymbol: false,
                        symbolSize: 10,
                        smooth: true,
                        itemStyle: {
                            color: colors[index],
                            borderWidth: 2,
                            borderColor: '#fff',
                            shadowColor: shadowColors[index],
                            shadowBlur: 20
                        },
                        areaStyle: {
                            color: new this.$echarts.graphic.LinearGradient(0, 0, 0, 1, [
                                {
                                    offset: 0,
                                    color: `rgba(${areaColors[index]}, 0.8)`
                                },
                                {
                                    offset: 0.5,
                                    color: `rgba(${areaColors[index]}, 0)`
                                }
                            ])
                        },
                    }
                    seriesData.push(yData)
                })
            }
            this.seriesData = [...seriesData];
            this.$nextTick(() => {
                if (!this.$isEmpty(data)) {
                    this.trendEcharts();
                }
                if (!this.trendSubjectSwiper) this.initSwiper(4);
                this.$forceUpdate();
            })
        },
        /** 获取当前班级数据 */
        async selectClass(sccla_id) {
            this.teacherSelectClassIds = sccla_id
            let { data } = await $getTeachSubject(sccla_id);
            this.teachSubject = data;
            let sub_id = [];    // 当前科目ID组
            if (!this.$isEmpty(data)) {
                data.map((item, index) => {
                    if (index < 3) sub_id.push(item.sysub_id)
                })
            }
            this.chartSubject = [...sub_id];
            this.getTeaChickSub();
        },
        /** 获取当前教师授课科目数据 */
        async getTeachSubject() {
            /* 获取默认班级、科目信息 */
            if (!this.$isEmpty(this.classData)) {
                this.teacherSelectClassIds = this.classData[0].sccla_id
            }
            let { data } = await $getTeachSubject(this.teacherSelectClassIds);
            this.teachSubject = data;
            let sub_id = [];    // 当前科目ID组
            if (!this.$isEmpty(data)) {
                data.map((item, index) => {
                    if (index < 3) sub_id.push(item.sysub_id)
                })
            }
            this.chartSubject = [...sub_id];
            this.getTeaChickSub();
        },
        /** 获取日课程表数据 */
        async getCourseArrangeData() {
            let { data } = await home.$dayTimeTable(formatTimeStamp(this.selectedDay));
            this.courseArrangeData = data;
            if (this.courseArrangeSwiper) this.courseArrangeSwiper.destroy(false);
            this.$nextTick(() => {
                if (!this.$isEmpty(data)) this.initSwiper(2);
            })
        },
        /** 获取消息通知列表 */
        async getNoticeData() {
            let { data } = await home.$msgList(10);
            this.noticeData = data.data
            if (!this.noticeSwiper) {
                this.$nextTick(() => {
                    this.initSwiper(1);
                })
            }
        },
        /** 获取待办列表 */
        async getScheduleList() {
            let { data } = await home.$scheduleList();
            this.scheduleData = data;
        },
        /** 获取学校通知（通知公告） */
        async getSchoolNotice() {
            let { data: schoolNotice } = await home.$schoolMsgList();
            this.schoolNoticeData = schoolNotice.data;
        },
        /** 添加课程 */
        async addCourse() {
            let { data } = await home.$scheduleTimeIsSet();
            if (data.exist == 1 && !this.$isEmpty(this.classData)) {
                this.classDialog = true
            } else {
                this.isExist = data.exist == 1;
                this.timeDialog = true;
            }
        },
        /** 切换展示日历月份 */
        changeMonth(type) {
            switch (type) {
                case 1:
                    if (this.currentMonth == 1) {
                        this.currentYear--;
                        this.currentMonth = 13

                    }
                    this.currentMonth--
                    break;
                case 2:
                    if (this.currentMonth == 12) {
                        this.currentYear++;
                        this.currentMonth = 0

                    }
                    this.currentMonth++
                    break;
                default:
                    break;
            }
            this.$forceUpdate();
        },
        /** 日历时间切换 */
        chooseDay(day) {
            this.selectedDay = day;
            this.getCourseArrangeData();
        },
        /** 去设置课程表时间 || 去添加班级 */
        setTimetable() {
            this.timeDialog = false;
            this.$router.push({
                name: !this.isExist && 'HOME_TIMETABLE' || this.$isEmpty(this.classData) && 'HOME_CLASSESGRADES'
            })
        },
        /** 初始化页面轮播实例 */
        initSwiper(val) {
            switch (val) {
                case 1:
                    this.noticeSwiper = new Swiper(".notice-wrapper", {
                        direction: "vertical",
                        speed: 800,
                        loop: true,
                        noSwiping: true,
                        noSwipingClass: 'notice-wrapper',
                        autoplay: {
                            delay: 3000
                        },
                    });
                    break;

                case 2:
                    this.courseArrangeSwiper = new Swiper(".course-arrange--wrapper", {
                        initialSlide: 0,
                        slidesPerView: "auto",
                        freeMode: true,
                        noSwiping: true,
                        observer: true,//修改swiper自己或子元素时，自动初始化swiper
                        observeParents: true,//修改swiper的父元素时，自动初始化swiper
                        noSwipingClass: 'course-arrange--wrapper',
                        navigation: {
                            nextEl: '.course-arrange--next',
                            prevEl: '.course-arrange--prev',
                        },
                    })
                    break;
                case 3:
                    this.classSwiper = new Swiper(".class--wrapper", {
                        initialSlide: 0,
                        slidesPerView: "auto",
                        freeMode: true,
                        noSwiping: true,
                        noSwipingClass: 'class--wrapper',
                        navigation: {
                            nextEl: '.class--next',
                            prevEl: '.class--prev',
                        },
                    })
                    break;
                case 4:
                    this.trendSubjectSwiper = new Swiper(".subject", {
                        initialSlide: 0,
                        slidesPerView: "auto",
                        freeMode: true,
                        noSwiping: true,
                        noSwipingClass: 'subject',
                        navigation: {
                            nextEl: '.subject--next',
                            prevEl: '.subject--prev',
                        },
                    })
                    break;
                default:
                    break;
            };
            this.$forceUpdate();
        },
        /** 实例化成绩趋势图表 */
        trendEcharts() {
            let echarts = this.$echarts.init(this.$refs.trend);
            echarts.resize();
            echarts.clear();
            let option = {
                tooltip: {
                    trigger: 'axis',
                    extraCssText: 'border-radius: 8px',
                    padding: [5, 10],
                    formatter: function (a) {
                        let list = []
                        let listItem = ''
                        for (var i = 0; i < a.length; i++) {
                            list.push(
                                `<p style="padding: 5px 0;" >
                                    <i style="display: inline-block;width: 8px;height: 8px;background:${a[i].color};border-radius: 2px;margin-right: 6px"></i>
                                    <span style="display: inline-block">${a[i].data}分</span>
                                </p>`
                            )
                        }
                        listItem = list.join('')
                        return `<div style="padding:6px;">${listItem}</div>`
                    },
                    textStyle: {
                        color: '#535353',
                        height: '24px',
                        fontSize: 14,
                        fontFamily: 'PingFang SC'
                    },
                    axisPointer: {
                        lineStyle: {
                            color: 'rgba(152, 126, 232, 0.26)'
                        }
                    },
                },
                xAxis: {
                    type: 'category',
                    boundaryGap: false,
                    data: this.xAxisData,
                    offset: 18,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    axisLabel: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                yAxis: {
                    type: 'value',
                    scale: true,
                    offset: 5,
                    max: 120,
                    min: 0,
                    interval: 10,
                    axisLine: {
                        show: false
                    },
                    axisTick: {
                        show: false
                    },
                    splitLine: {
                        lineStyle: {
                            // 使用深浅的间隔色
                            color: ['rgba(233,233,244,0.5)'],
                            type: 'dashed'
                        }
                    },
                    axisLabel: {
                        color: '#282828',
                        fontFamily: 'PingFang SC'
                    }
                },
                grid: {
                    show: false,
                    top: "30px",
                    left: "70px",
                    right: "30px",
                    bottom: "140px"
                },
                series: [...this.seriesData],
            };
            echarts.setOption(option, true);
        },
        /** 关闭班级选择弹窗 */
        hideDialog() {
            this.classDialog = false;
            this.$refs['timeAboutClass'].resetFields();
            this.timeAboutClass = {};
        },
        /** 跳转添加班级课程表 */
        tapLessonSet() {
            this.$refs.timeAboutClass.validate(async (valid) => {
                if (valid) {
                    let { class: classInfo } = this.timeAboutClass;
                    classInfo = classInfo.split(',');
                    this.hideDialog();
                    this.$router.push({ name: 'HOME_LESSONSET', params: { grade: classInfo[0], class: classInfo[1] } });
                }
            })
        },
        /**
         * 跳转去备课
         * @param {number} status 课程状态
         */
        toPrepare(status) {
            if (status == 2) {
                this.$router.push({ name: 'TEACHING_PREPARE' })
            }
        },
        isupgradation(){
            if(parseInt(sessionStorage.getItem("is_enter_school")) == 2){
                this.$confirm("请确认是否进行学期升级调整？", "提示", {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning',
                    callback: () => {},
                    beforeClose: (action, ctx, close) => {
                        if (action !== 'confirm') {
                            close();
                            return;
                        }
                        ctx.confirmButtonLoading = true;
                        home.upgradation().then((res2)=>{
                          if (res2.status == 200) {
                            close();
                            sessionStorage.setItem("is_enter_school", 1);
                            // 刷新班级
                            this.$message({
                              message: '升级成功',
                              type: 'success'
                            });
                            ctx.confirmButtonLoading = false;
                            location.reload();
                            this.getClass()
                          }else{
                            ctx.confirmButtonLoading = false;
                            close();
                            sessionStorage.setItem("is_enter_school", 3);
                          }
                        }).catch((e)=>{
                          ctx.confirmButtonLoading = false;
                        })
                    }
                }).finally(() => {})
            }
        }
    },
}
</script>